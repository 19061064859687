import React, { Fragment } from 'react';
export const FacebookAdViaGoogleTags = () => {
  return (
    <Fragment>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          <!-- Google Tag Manager -->
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T92462K3');
          <!-- End Google Tag Manager -->
    `,
        }}
      ></script>
    </Fragment>
  );
};

export const FacebookAdViaGoogleTagsNoScript = () => {
  return (
    <React.Fragment>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T92462K3"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </React.Fragment>
  );
};
