import React, { useEffect, useState, useCallback } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Layout, Grid } from 'antd';
import GeneralHeader from '../components/GeneralHeader';
import { GeneralWebSiteFooter } from '../components/GeneralFooter';
import styles from './general.less';
import { JoinClassName } from 'src/utils/utils';
import useLocale from 'src/hooks/useLocale';
import WebSiteFonts from 'src/third_party/WebSiteFonts';
import { FacebookPixel } from 'src/third_party/FacebookPixel';
import { POS_VISITOR } from 'src/consts/third_party';
import JSONLDHead from 'src/third_party/JSONLDHead';
import useJSONLDMeta from 'src/hooks/useJSONLDMetaHook';
import PopupDemoForm from 'src/components/POSWebsite/DemoFormForGoogleAds';
import useGoogleAds from 'src/hooks/googleAdsHook';
import { HubSpot } from 'src/third_party/HubSpot';
import { FacebookAdViaGoogleTags, FacebookAdViaGoogleTagsNoScript } from 'src/third_party/FacebookAdViaGoogleTags';

const Favicon = `${process.env.ASSETS_PREFIX}/assets/favicon.png`;
const { Content } = Layout;
const TITLE = 'Chowbus: Restaurant POS & Management System';
const CHOWBUS_ONE_DESCRIPTION = `Revolutionize your restaurant with Chowbus POS - the all-in-one restaurant POS system. Save $8,000/mo in labor costs and boost growth by 25%. See how it works!`;
const ogFeaturedImage = 'https://pos-static.chowbus.com/assets/logo@3x.png';

const { useBreakpoint } = Grid;

const EXCLUDE_GOOGLE_ADS_TRACKING_PAGES = ['pos-get-a-demo'];
const GeneralLayout = (props) => {
  const router = useRouter();
  const url = `https://pos.chowbus.com${router.asPath}`;
  const screens = useBreakpoint();
  const _locale_ = useLocale();
  let intercomAppId = process.env.INTERCOM_APP_ID || 'gvnavg88';
  const pageJSONLDList = useJSONLDMeta({ pageId: props.pageId });
  const { googleAdsUTMData, updateDemoPopupShown } = useGoogleAds();
  const [showPopupDemoForm, setShowPopupDemoForm] = useState(false);

  useEffect(() => {
    let intercom;
    let intercomManager;

    function initedCallback() {
      intercom = intercomManager.getById(intercomAppId);
      intercom.sendBoot({ userType: POS_VISITOR });
    }

    async function setupIntercom() {
      intercomManager = (await import('../third_party/Intercom')).intercomManager;
      intercomManager.setShowMessage(true);
      intercomManager.initIntercom({ intercomAppId, initedCallback });
    }

    setupIntercom();

    return () => {
      intercomManager.setShowMessage(false);
      intercom && intercom.shutdown();
    };
  }, []);

  useEffect(() => {
    let showDemoPopupTimer = null;
    if (!googleAdsUTMData || !props.pageId || EXCLUDE_GOOGLE_ADS_TRACKING_PAGES.indexOf(props.pageId) !== -1) {
      document.body.style.removeProperty('overflow');
      return;
    }
    if (googleAdsUTMData && !googleAdsUTMData.shown) {
      showDemoPopupTimer = setTimeout(() => {
        console.log('show popup demo form');
        setShowPopupDemoForm(true);
        document.body.style.setProperty('overflow', 'hidden');
        updateDemoPopupShown();
      }, 5000);
    } else {
      if (googleAdsUTMData && googleAdsUTMData.shown && googleAdsUTMData.closed) {
        setShowPopupDemoForm(false);
        document.body.style.removeProperty('overflow');
      }
    }

    return () => {
      showDemoPopupTimer && clearTimeout(showDemoPopupTimer);
    };
  }, [googleAdsUTMData, updateDemoPopupShown, props.pageId]);

  const onClosePopupDemoForm = useCallback(() => {
    updateDemoPopupShown({ closed: true });
  }, [updateDemoPopupShown]);

  return (
    <React.Fragment>
      <Head>
        <title>{props.pageTitle || TITLE}</title>
        <link rel="icon" type="image/x-icon" href={Favicon} />
        <link rel="canonical" href={url} />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta charSet="utf-8" />
        <meta name="google-site-verification" content="0jiLDUpy4VWJ5KRQWIAfMO-83WMJ0Flpv22S_DFayWc" />
        <meta name="description" content={props.metaDescription || props.pageTitle || TITLE} />
        {process.env.STAGING_ENV !== 'production' ? <meta name="robots" content="noindex" /> : null}
        <link rel="alternate" hrefLang="zh-Hans" href={`https://pos.chowbus.com/zh${router.asPath}`} />
        <link rel="alternate" hrefLang="en-us" href={`https://pos.chowbus.com/en${router.asPath}`} />
        <link rel="alternate" hrefLang="x-default" href={`https://pos.chowbus.com${router.asPath}`} />

        <meta property="og:site_name" content="Chowbus POS" />
        <meta property="og:title" content={props.pageTitle || TITLE} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={props.pageFeaturedImage || ogFeaturedImage} />
        <meta property="og:description" content={props.metaDescription || props.pageTitle || TITLE} />

        <meta itemProp="name" content="Chowbus POS" />
        <meta itemProp="url" content={url} />
        <meta itemProp="description" content={props.metaDescription || props.pageTitle || TITLE} />

        <meta name="twitter:title" content={props.pageTitle || TITLE} />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:card" content={props.pageTitle || TITLE} />
        <meta name="twitter:description" content={props.metaDescription || props.pageTitle || TITLE} />
        <meta name="p:domain_verify" content="9a0b8e95e415ef8c2db97abd1cca42c1" />

        <FacebookPixel />
        <HubSpot />
        <FacebookAdViaGoogleTags />
      </Head>
      <React.Fragment>
        {pageJSONLDList.map((pageJSONLDObject, index) => {
          const jsonLD = JSON.stringify(pageJSONLDObject, null, '\t');
          return <JSONLDHead jsonLD={jsonLD} key={index} />;
        })}
      </React.Fragment>
      <Layout>
        <FacebookAdViaGoogleTagsNoScript />
        <GeneralHeader locale={_locale_} asPath={router.asPath} ownerPageId={props.pageId || ''} />
        <Layout
          className={JoinClassName(
            `${styles.Layout}`,
            `${props.pageId || ''}`,
            `${props.pageClass || ''}`,
            screens.xs ? 'forMobile' : '',
            screens.md ? 'forMDDevice' : '', //768
            screens.sm && !screens.md ? 'forSMDevice' : '' //576
          )}
        >
          <Content className={styles.Content}>{props.children}</Content>
        </Layout>
        <GeneralWebSiteFooter locale={_locale_} />
        <WebSiteFonts />
        {showPopupDemoForm && <PopupDemoForm onClose={onClosePopupDemoForm} />}
      </Layout>
    </React.Fragment>
  );
};

export default GeneralLayout;
