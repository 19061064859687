import React, { Fragment } from 'react';
export const HubSpot = () => {
  return (
    <Fragment>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/47969074.js"
        dangerouslySetInnerHTML={{
          __html: `
          <!-- Start of HubSpot Embed Code -->
          <!-- End of HubSpot Embed Code -->
    `,
        }}
      ></script>
    </Fragment>
  );
};
